// @flow
/*
  Copyright(c) 2018 Uber Technologies, Inc.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

          http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
*/

import { type IEdge } from '../../components/edge';
import { type INode } from '../../components/node';

export type IGraphInput = {
  nodes: INode[],
  edges: IEdge[],
};

export default class Transformer {
  /**
   * Converts an input from the specified type to IGraphInput type.
   * @param input
   * @returns IGraphInput
   */
  static transform(input: any): IGraphInput {
    return {
      edges: [],
      nodes: [],
    };
  }

  /**
   * Converts a graphInput to the specified transformer type.
   * @param graphInput
   * @returns any
   */
  static revert(graphInput: IGraphInput): any {
    return graphInput;
  }
}
